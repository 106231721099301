@use "../../assets/scss/variables" as _media;

.footer {
  background-color: var(--color-white);
  display: flex;
  padding: 34px 0;

  @media screen and (min-width: _media.$start-media-table) {
    border-top: 1px solid var(--color-border);
  }


  &__container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: _media.$end-media-mobile) {
      flex-wrap: wrap;
    }
  }

  .main-logo {
    max-width: 320px;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: _media.$end-media-mobile) {
      //order: 1;
      margin-bottom:  15px;
    }

    &__img{
      width: 100%;
    }

  }

  .gov-logo{
    max-width: 160px;
    margin-left: 20px;
    flex: auto;

    margin-right: 20px;

    @media screen and (max-width: _media.$end-media-mobile) {
      margin-bottom:  15px;
    }

    &__img{
      width: 100%;
    }
  }

  .second-logo {
    max-width: 44px;
    margin-left: 20px;

    @media screen and (max-width: _media.$end-media-mobile) {
      //order: 2;
      margin-bottom:  15px;
    }

    @media screen and (min-width: _media.$start-media-table) {
      max-width: 71px;

    }

  }

  &__text {

    color: var(--color-gray-l);
    font-size: var(--text-P5);
    line-height: 21px;

    @media screen and (max-width: _media.$end-media-mobile) {
      order: 3;
      margin-top: 12px;
    }
    @media screen and (min-width: _media.$start-media-table) {
      font-size: var(--text-P6);
      flex: 1;
      max-width: 640px;
    }
  }

}



