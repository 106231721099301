@font-face {
  font-family: 'e-Ukraine Head';
  src: url('../../assets/fonts/e-UkraineHead-Regular.eot');
  src: url('../../assets/fonts/e-UkraineHead-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/e-UkraineHead-Regular.woff2') format('woff2'),
  url('../../assets/fonts/e-UkraineHead-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'e-Ukraine Head';
  src: url('../../assets/fonts/e-UkraineHead-Light.eot');
  src: url('../../assets/fonts/e-UkraineHead-Light.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/e-UkraineHead-Light.woff2') format('woff2'),
  url('../../assets/fonts/e-UkraineHead-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'e-Ukraine';
  src: url('../../assets/fonts/e-Ukraine-Light.eot');
  src: url('../../assets/fonts/e-Ukraine-Light.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/e-Ukraine-Light.woff2') format('woff2'),
  url('../../assets/fonts/e-Ukraine-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'e-Ukraine Head';
  src: url('../../assets/fonts/e-UkraineHead-Bold.eot');
  src: url('../../assets/fonts/e-UkraineHead-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/e-UkraineHead-Bold.woff2') format('woff2'),
  url('../../assets/fonts/e-UkraineHead-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'e-Ukraine Head';
  src: url('../../assets/fonts/e-UkraineHead-Medium.eot');
  src: url('../../assets/fonts/e-UkraineHead-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/e-UkraineHead-Medium.woff2') format('woff2'),
  url('../../assets/fonts/e-UkraineHead-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'e-Ukraine Head LOGO';
  src: url('../../assets/fonts/e-UkraineHead-LOGO.eot');
  src: url('../../assets/fonts/e-UkraineHead-LOGO.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/e-UkraineHead-LOGO.woff2') format('woff2'),
  url('../../assets/fonts/e-UkraineHead-LOGO.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'e-Ukraine';
  src: url('../../assets/fonts/e-Ukraine-UltraLight.eot');
  src: url('../../assets/fonts/e-Ukraine-UltraLight.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/e-Ukraine-UltraLight.woff2') format('woff2'),
  url('../../assets/fonts/e-Ukraine-UltraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'e-Ukraine';
  src: url('../../assets/fonts/e-Ukraine-Thin.eot');
  src: url('../../assets/fonts/e-Ukraine-Thin.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/e-Ukraine-Thin.woff2') format('woff2'),
  url('../../assets/fonts/e-Ukraine-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'e-Ukraine';
  src: url('../../assets/fonts/e-Ukraine-Regular.eot');
  src: url('../../assets/fonts/e-Ukraine-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/e-Ukraine-Regular.woff2') format('woff2'),
  url('../../assets/fonts/e-Ukraine-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'e-Ukraine Head';
  src: url('../../assets/fonts/e-UkraineHead-Thin.eot');
  src: url('../../assets/fonts/e-UkraineHead-Thin.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/e-UkraineHead-Thin.woff2') format('woff2'),
  url('../../assets/fonts/e-UkraineHead-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'e-Ukraine';
  src: url('../../assets/fonts/e-Ukraine-Medium.eot');
  src: url('../../assets/fonts/e-Ukraine-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/e-Ukraine-Medium.woff2') format('woff2'),
  url('../../assets/fonts/e-Ukraine-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'e-Ukraine Head';
  src: url('../../assets/fonts/e-UkraineHead-UltraLight.eot');
  src: url('../../assets/fonts/e-UkraineHead-UltraLight.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/e-UkraineHead-UltraLight.woff2') format('woff2'),
  url('../../assets/fonts/e-UkraineHead-UltraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'e-Ukraine';
  src: url('../../assets/fonts/e-Ukraine-Bold.eot');
  src: url('../../assets/fonts/e-Ukraine-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/e-Ukraine-Bold.woff2') format('woff2'),
  url('../../assets/fonts/e-Ukraine-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
