@use "../../assets/scss/variables" as _media;

.loader-is {
  height: 100px;
  width: 100%;
  text-align: center;
  padding: 1em;
  margin: 0 auto 1em;
  display: inline-block;
  vertical-align: top;

  @media screen and (min-width: _media.$start-media-table) {

  }
}
