@use "../../assets/scss/variables" as _media;

.form {
  position: relative;
  display: flex;
  //flex-wrap: nowrap;
  flex-direction: column;
  overflow: hidden;
  //min-height: 400px;


  &__field-wrap {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
    @media screen and (min-width: _media.$start-media-table) {
      margin-bottom: 26px;
    }
  }

  &__links {
    margin-top: 26px;
  }

  &__link {
    font-size: 18px;
    color: var(--color-links);
    text-decoration: underline;

    &:hover {
      opacity: 0.8;
    }
  }

  &__label {
    padding: 0 15px 8px;
    font-size: var(--text-P6);
    color: var(--color-gray-m);
    display: inline-block;
    cursor: default;

    @media screen and (min-width: _media.$start-media-table) {
      font-size: var(--text-P5);
    }

    &-require {
      color: var(--red-require);
    }
  }

  &__sub-label {
    font-size: 16px;
    margin-top: -5px;
    margin-bottom: 5px;
    line-height: 20px;
    padding-right: var(--m-outside);
    padding-left: var(--m-outside);
    color: #738e82;
  }

  &__require {
    color: var(--red-require);
  }

  &__invalid {
    &-block {
      margin-top: 20px;
      @media screen and (min-width: _media.$start-media-table) {
        margin-top: 24px;
      }
      color: var(--color-invalid);
    }

    &-icon {
      width: 21px;
      height: 17px;
      margin-bottom: -3px;
      margin-left: 5px;
    }
  }

  &__error {
    display: inline-block;
    font-size: var(--text-P6);
    margin-top: 8px;
    margin-left: 8px;
    color: var(--color-invalid);
    @media screen and (min-width: _media.$start-media-table) {
      font-size: var(--text-P5);
    }
  }

  &__bad-request {
    display: inline-block;
    font-size: var(--text-P5);
    margin: 30px auto 0;
    color: var(--color-invalid);
    max-width: 316px;
    line-height: 20px;
    text-align: center;

    span {
      color: inherit;
      font-size: inherit;
    }
  }

}
