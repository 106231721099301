:root {
  // FONTS ------------------------
  --font-main: 'e-Ukraine', sans-serif;

  // COLORS ------------------------
  --color-white: #ffffff;
  --color-black: #000000;
  --color-gray-h: #666666;
  --color-gray-m: #959595;
  --color-gray-l: #A1A1A1;
  --color-invalid: #FF3800;

  --bg-main: #E6EBE6;
  --bg-gray: #F5F6FA;
  --bg-white: #ffffff;
  --bg-black: #000;
  --bg-invalid: #ffc3c3;
  --bg-blue: #5B5AFF;
  --color-blue: #5B5AFF;
  --color-border: #E6EBE6;


  // ANIMATION ------------------------
  --anim-fast: 0.15s cubic-bezier(0, 0, 0.3, 1);
  --anim-slow: 0.25s cubic-bezier(0, 0, 0.3, 1);

  // MARGINS ------------------------
  --m-outside: 16px;
  --m-inside: 16px;

  // SIZE -----------------------
  --max-w: 1460px;

  //FONT-SIZE ----------------------
  --main-title: 36px;
  --second-title: 34px;
  --default-size: 14px;

  --text-P1: 26px;
  --text-P2: 18px;

  --text-P3: 24px;
  --text-P4: 16px;
  --text-P5: 14px;
  --text-P6: 12px;


  --height-header: 82px;
  --height-footer: 132px;
  --shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
}

@media screen and (min-width: 768px) {
  :root {
    //FONT-SIZE ----------------------
    --main-title: 34px;
    --second-title: 34px;
    --default-size: 18px;


    --text-P1: 48px;
    --text-P2: 36px;
    --text-P3: 24px;
    --text-P4: 18px;
    --text-P5: 16px;
    --text-P6: 14px;

    // MARGINS ------------------------
    --m-outside: 66px;
    --m-inside: 50px;


    --height-header: 100px;
  }
}

/*@media screen and (max-width: 340px) {
  :root {
    //FONT-SIZE ----------------------
    --main-title: 34px;
    --second-title: 34px;
    --default-size: 18px;

    // MARGINS ------------------------
  }
}*/


//SCSS VARIABLE
$start-media-desktop: 1024px;
$start-media-table: 768px;
$end-media-table: 1023px;
$end-media-mobile: 767px;
$end-media-mini-mob: 330px;

