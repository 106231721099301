@use "variables" as c;

.m-width {
  //max-width: calc(var(--max-w) + var(--m-outside) * 2);
  max-width: var(--max-w);
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: var(--m-outside);
  padding-right: var(--m-outside);
}

.main-title {
  font-size: var(--main-title);
  margin-top: 0;
  font-weight: bold;
  margin-bottom: 15px;
  line-height: 44px;
}

.second-title {
  font-size: var(--second-title);
  margin-top: 0;
  font-weight: bold;
  line-height: 44px;
  //margin-bottom: 22px;
}


.loading {
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  //background-color: var(--bg-light-blue);
}


[class^=icon-]:before, [class*=" icon-"]:before {
  width: auto;
  margin: auto;
}

.ul-custom{
  list-style: circle;
  padding-left: 22px;
  margin-bottom: 20px;
}

.main-bacg-loader{
  position: fixed;
  z-index: 1;
  top:0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-white);
}