.container {
  $this: 'content';
  //margin-top: var(--height-header);
  min-height: calc(100vh - var(--height-header) - var(--height-footer));
  //min-height: calc(100vh - 100vh / 50);
  //overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;

  &__child {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    /*    &--second {
          margin: calc(var(--height-header) + var(--m-vertical)) var(--m-horizont) var(--m-vertical);
        }

        &:not(.container__child--second) {
          .content-wrap {
            padding-top: 50px;
            padding-bottom: 50px;
          }
        }*/
  }
}
