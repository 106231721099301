@use "../../assets/scss/variables" as _media;

.header {
  display: flex;
  width: 100%;
  height: var(--height-header);
  background-color: var(--color-white);

  @media screen and (min-width: _media.$start-media-table) {
    border-bottom: 1px solid var(--color-border);
  }

  &__container{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
  }

  .main-logo{
    max-width: 320px;
    margin-right: 28px;
    flex: 1;
  }
  .second-logo{
    max-width: 44px;

    @media screen and (min-width: _media.$start-media-table) {
      max-width: 71px;
    }
  }

}

